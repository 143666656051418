<template>
  <div class="contents">
    <div class="title">
      <h1 v-html="$t('common.top_title', {nickname: myInfo.user_nickname})"></h1>
      <p>{{ $t('common.top_subtitle') }}</p>
    </div>
    <div class="smenu">
      <div class="mypage_sub_title">
        <a href="javascript:" @click="this.$router.back()">
          <img src="../../assets/img/btn_back.png">
        </a>{{ $t('notify_setting.title') }}
      </div>
    </div>

    <div class="warp">
      <div class="nofity_setting">
        <ul>
          <li>
            {{ $t('notify_setting.video_notify') }}
            <a href="javascript:" :class="{on: myInfo.user_video_alarm == 0}" @click="onVideoNotify">
              <p>{{ myInfo.user_video_alarm == 0 ? 'ON' : 'OFF' }}</p>
            </a>
          </li>
          <li>
            {{ $t('notify_setting.video_notify_global') }}
            <a href="javascript:" :class="{on: myInfo.user_video_alarm_global == 0}" @click="onVideoNotifyGlobal">
              <p>{{ myInfo.user_video_alarm_global == 0 ? 'ON' : 'OFF' }}</p>
            </a>
          </li>
          <li>
            {{ $t('notify_setting.video_notify_sound') }}
            <a href="javascript:" :class="{on: NotifySound == 'on'}" @click="onVideoNotifySound">
              <p>{{ NotifySound == 'on' ? 'ON' : 'OFF' }}</p>
            </a>
          </li>
          <li>
            {{ $t('notify_setting.video_notify_vibrate') }}
            <a href="javascript:" :class="{on: NotifyVibrate == 'on'}" @click="onVideoNotifyVibration">
              <p>{{ NotifyVibrate == 'on' ? 'ON' : 'OFF' }}</p>
            </a>
          </li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./NotifySetting.scss"></style>

<script>
import { loadData, requestPost, saveData, showToast } from "../../lib/util";

export default {
  name: 'NotifySetting',
  components: {},
  data() {
    let myInfo = this.$store.getters.me;
    return {
      myInfo: myInfo,
      NotifySound: loadData('NotifySound', 'on'),
      NotifyVibrate: loadData('NotifyVibrate', 'on'),
    }
  },
  mounted() {
  },
  methods: {
    onVideoNotify: function () {
      const me = this.myInfo;
      const _app = this;

      requestPost(
        'user/setVideoNotify',
        {
          uid: me.uid,
          video_notify: (_app.myInfo.user_video_alarm + 1) % 2,
          video_notify_global: Number(_app.myInfo.user_video_alarm_global),
        },
        async response => {
          _app.myInfo.user_video_alarm = (_app.myInfo.user_video_alarm + 1) % 2;
          _app.$store.dispatch('setMyInfo', _app.myInfo);
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },

    onVideoNotifyGlobal: function () {
      const me = this.myInfo;
      const _app = this;

      requestPost(
        'user/setVideoNotify',
        {
          uid: me.uid,
          video_notify: Number(_app.myInfo.user_video_alarm),
          video_notify_global: (_app.myInfo.user_video_alarm_global + 1) % 2,
        },
        async response => {
          _app.myInfo.user_video_alarm_global = (this.myInfo.user_video_alarm_global + 1) % 2;
          _app.$store.dispatch('setMyInfo', this.myInfo);
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onVideoNotifySound: function () {
      this.NotifySound = this.NotifySound == 'on' ? 'off' : 'on';
      this.myInfo.NotifySound = !this.myInfo.NotifySound;
      this.$store.dispatch('setMyInfo', this.myInfo);
    },
    onVideoNotifyVibration: function () {
      this.NotifyVibrate = this.NotifyVibrate == 'on' ? 'off' : 'on';
      this.myInfo.NotifyVibrate = !this.myInfo.NotifyVibrate;
      this.$store.dispatch('setMyInfo', this.myInfo);
    },
  },
}
</script>
